import Alert from "react-bootstrap/Alert";
import React, { useState } from "react";

const Error = (props) => {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{props.title}</Alert.Heading>
        <p>{props.message}</p>
      </Alert>
    );
  }
};
export default Error;
