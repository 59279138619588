// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NFTelement_card__khh9e {\n  background-color: #fff;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 10px;\n  margin: 0;\n}\n\n.NFTelement_subtitle__g47xb {\n  color: #000;\n  text-align: center;\n  font-size: 0.8rem;\n  padding-top: 0.5rem;\n}\n\n.NFTelement_NFTimage__6pYUb {\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n  transition: 0.5s;\n  overflow: hidden;\n}\n\n.NFTelement_cardGroup__1AemD {\n  margin: 5px;\n  padding: 0;\n}\n\n.NFTelement_NFTcard__22SpF {\n  margin: 0;\n  padding: 5%;\n  transition: 0.5s;\n}\n.NFTelement_NFTcard__22SpF:hover {\n  transform: translate(0, -10px);\n  transition: 0.5s;\n}\n@media screen and (max-width: 560px) {\n  .NFTelement_NFTcard__22SpF:hover {\n    transform: none;\n    transition: 0.5s;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/component/NFT/NFTelement.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yCAAyC;EACzC,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".card {\n  background-color: #fff;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 10px;\n  margin: 0;\n}\n\n.subtitle {\n  color: #000;\n  text-align: center;\n  font-size: 0.8rem;\n  padding-top: 0.5rem;\n}\n\n.NFTimage {\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n  transition: 0.5s;\n  overflow: hidden;\n}\n\n.cardGroup {\n  margin: 5px;\n  padding: 0;\n}\n\n.NFTcard {\n  margin: 0;\n  padding: 5%;\n  transition: 0.5s;\n}\n.NFTcard:hover {\n  transform: translate(0, -10px);\n  transition: 0.5s;\n}\n@media screen and (max-width: 560px) {\n  .NFTcard:hover {\n    transform: none;\n    transition: 0.5s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "NFTelement_card__khh9e",
	"subtitle": "NFTelement_subtitle__g47xb",
	"NFTimage": "NFTelement_NFTimage__6pYUb",
	"cardGroup": "NFTelement_cardGroup__1AemD",
	"NFTcard": "NFTelement_NFTcard__22SpF"
};
export default ___CSS_LOADER_EXPORT___;
