import classes from "../pages/SubmitForm.module.css";
import SubButton from "../UI/SubButton";

const ConfirmInfo = (probs) => {
  return (
    <>
      <h3 className="title">確認資訊</h3>
      <p className="subtitle">
        姓名：{probs.name}
        <br />
        Email：{probs.email}
        <br />
        電話：{probs.contactphone}
        <br />
        領取錢包地址：
        <br />
        {probs.function(probs.address, 15)}
        <br />
        {probs.currency !== "" ? `幣別：` : ``}
        {probs.currency}
        <br />
        {probs.swiftcode !== "" ? `swiftcode：` : ``}
        {probs.swiftcode}
        <br />
        {probs.bankcode !== "" ? `銀行代碼：` : ``}
        {probs.bankcode}
        <br />
        {/* {probs.bankname !== "" ? `匯款分行名稱：` : ``}
        {probs.bankname}
        <br /> */}
        {probs.account !== "" ? `銀行帳號：` : ``}
        {probs.account}
        {/* <br />
        {probs.accountname !== "" ? `匯款帳號戶名：` : ``}
        {probs.accountname} */}
      </p>
      <div className={classes.buttonList}>
        <div className={classes.buttonList}>
          <SubButton onClick={probs.lefthandler}>上一步修改</SubButton>
        </div>
        <div className={classes.buttonList}>
          <button className={classes.button} onClick={probs.righthandler}>
            確認送出
          </button>
        </div>
      </div>
    </>
  );
};
export default ConfirmInfo;
