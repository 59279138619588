import Alert from "react-bootstrap/Alert";

const Success = (props) => {
  return (
    <Alert variant="success">
      <Alert.Heading>{props.title}</Alert.Heading>
      <p>{props.subTitle}</p>
      <hr />
      <p className="mb-0">{props.message}</p>
    </Alert>
  );
};
export default Success;
