import classes from "./Header.module.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
// import Button from "./Button";
import { ConnectButton } from "@rainbow-me/rainbowkit";
// import logo from "../public/logo.png";

const Header = () => {
  return (
    <Navbar bg="light" expand="lg" variant="light" sticky="top">
      <Container>
        <div style={{ width: "50%" }}>
          <a
            href="https://www.pgtalk.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/logo.png" className={classes.headerLogo} alt="logo" />
          </a>
        </div>

        <Nav className="justify-content-end">
          {/* <Button className={classes.headerBtn}>連結錢包</Button> */}
          <ConnectButton />
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
