import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "./component/UI/Card";
import Header from "./component/UI/Header";
import Container from "react-bootstrap/Container";
import NFTelement from "./component/NFT/NFTelement";
import Home from "./component/pages/Home";

const nfts = [
  {
    filename: "1.png",
    subtitle: "第一波",
    url: "https://opensea.io/collection/pgtalk",
  },
  {
    filename: "2.png",
    subtitle: "第二波",
    url: "https://opensea.io/collection/pgtalk-giftrabbit",
  },
  {
    filename: "3.png",
    subtitle: "第三波",
    url: "https://opensea.io/collection/pgtalk-dog-egg",
  },
  {
    filename: "4.png",
    subtitle: "第四波",
    url: "https://opensea.io/collection/pgtalk-fennecfox",
  },
  {
    filename: "5.png",
    subtitle: "第五波",
    url: "https://opensea.io/collection/pgtalk-uni",
  },
  {
    filename: "6.png",
    subtitle: "第六波",
    url: "https://opensea.io/collection/pgtalk-p-bird",
  },
];
const teachingLinks = [
  {
    url: "https://pgtalk.mypinata.cloud/ipfs/QmYHnMQgNdnAQoEK7zkxk4V52KXV7Sm8ZfsuoebYQgBiGa/NFT%E6%8E%9B%E8%B3%A3%E6%95%99%E5%AD%B8.mp4",
    subtitle: "NFT掛賣教學",
  },
  {
    url: "https://pgtalk.mypinata.cloud/ipfs/QmYHnMQgNdnAQoEK7zkxk4V52KXV7Sm8ZfsuoebYQgBiGa/POLYGON%E9%8F%88%E5%BB%BA%E7%AB%8B&NFT%E5%91%88%E7%8F%BE.mp4",
    subtitle: "POLYGON鏈建立&NFT呈現",
  },
  {
    url: "https://pgtalk.mypinata.cloud/ipfs/QmYHnMQgNdnAQoEK7zkxk4V52KXV7Sm8ZfsuoebYQgBiGa/%E7%8B%90%E7%8B%B8%E9%8C%A2%E5%8C%85%E5%BB%BA%E7%AB%8B%E6%95%99%E5%AD%B8.mp4",
    subtitle: "狐狸錢包建立教學",
  },
];

function App() {
  return (
    <>
      <Header />
      <Container>
        <Card className="appCard">
          <img src="/logo.png" className="App-logo" alt="logo" />
          <Home />
        </Card>
        <div className="listTitle">
          <div className="listBorder">
            <h1>鴿子幣NFT</h1>
          </div>
        </div>
        <div className="NFTlist">
          {nfts.map((nft) => (
            <NFTelement
              key={nft.filename}
              filename={nft.filename}
              subtitle={nft.subtitle}
              url={nft.url}
            />
          ))}
        </div>
      </Container>
      <Container>
        <Card>
          <h3>操作教學</h3>
          {teachingLinks.map((teachingLink) => (
            <>
              <a
                href={teachingLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {teachingLink.subtitle}
              </a>
              <br />
            </>
          ))}
        </Card>
      </Container>
    </>
  );
}

export default App;
