import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "../UI/Button";
import { useAccount } from "wagmi";
import { Container } from "react-bootstrap";
import GroupInputStr from "../form/GroupInputStr";
import GroupPhoneNumber from "../form/GroupPhoneNumber";
import GroupWalletAddress from "../form/GroupWalletAddress";
import ConfirmInfo from "../form/ConfirmInfo";
import useHttps from "../../hooks/use-https";
import bankCode from "./bankCode.json";
import GroupBankCode from "../form/GroupBankCode";

const SubmitForm = (probs) => {
  const { address } = useAccount();
  const [validated, setValidated] = useState(false);
  const [enteredName, setEnteredName] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPhone, setEnteredPhone] = useState("");
  const [enteredBankCode, setEnteredBankCode] = useState("");
  // const [enteredBankName, setEnteredBankName] = useState("");
  const [enteredSwiftCode, setenteredSwiftCode] = useState("");
  const [enteredAccount, setEenteredAccount] = useState("");
  // const [enteredAccountName, setEnteredAccountName] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const showFormHandler = () => {
    setShowForm(true);
    setShowSuccess(false);
  };
  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
  };
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };
  const phoneChangeHandler = (event) => {
    setEnteredPhone(event.target.value);
  };
  const bankCodeChangeHandler = (event) => {
    setEnteredBankCode(event.target.value);
  };
  // const bankNameChangeHandler = (event) => {
  //   setEnteredBankName(event.target.value);
  // };
  const swiftCodeChangeHandler = (event) => {
    setenteredSwiftCode(event.target.value);
  };
  const accountChangeHandler = (event) => {
    setEenteredAccount(event.target.value);
  };
  // const accountNameChangeHandler = (event) => {
  //   setEnteredAccountName(event.target.value);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setShowForm(false);
    }
    setValidated(true);
  };
  const truncate = (input, len) =>
    input?.length > len ? `${input?.substring(0, len)}...` : input;

  let payload = {
    action: "userdata",
    userdata: {
      TokenID: "",
      RoundOne: false,
      RoundTwo: false,
      RoundThree: false,
      Name: enteredName,
      ContactMethod: enteredPhone,
      Email: enteredEmail,
      WalletAddress: address,
      Currency: probs.type,
      BankNumber: enteredBankCode,
      // BankName: enteredBankName,
      AccountNumber: enteredAccount,
      // AccountName: enteredAccountName,
      SWIFTcode: enteredSwiftCode,
    },
  };
  const { response, isLoading, err, sendRequest } = useHttps(payload);

  const submitSuccessHandler = () => {
    probs.res.map((prob) => {
      payload.userdata.TokenID = prob.TokenId;
      payload.userdata.RoundOne = prob.RefundOneStatus;
      payload.userdata.RoundTwo = prob.RefundTwoStatus;
      payload.userdata.RoundThree = prob.RefundThreeStatus;
      sendRequest();
    });
    probs.onSuccess();
    setShowSuccess(true);
  };

  return (
    <>
      {showForm && !showSuccess && (
        <Container style={{ marginTop: "30px" }}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <GroupInputStr
              label="姓名"
              type="text"
              handler={nameChangeHandler}
              value={enteredName}
              feedback="請輸入正確的姓名"
            />
            <GroupInputStr
              label="Email"
              type="email"
              handler={emailChangeHandler}
              value={enteredEmail}
              feedback="請輸入有效的Email"
            />
            <GroupPhoneNumber
              handler={phoneChangeHandler}
              value={enteredPhone}
            />
            {probs.type === `USDT` && (
              <GroupWalletAddress address={address} function={truncate} />
            )}
            {(probs.type === "TWD" || probs.type === "USD") && (
              <GroupBankCode
                handler={bankCodeChangeHandler}
                select={probs.selected}
                bankcode={bankCode["本國銀行"]}
              />
            )}
            {/* {probs.type === "TWD" && (
              <GroupInputStr
                label="匯款分行名稱"
                type="text"
                handler={bankNameChangeHandler}
                value={enteredBankName}
                feedback="請輸入有效的匯款分行名稱，例如：台北富邦銀行/台北分行"
              />
            )} */}

            {probs.type === "TWD" && (
              <GroupInputStr
                label="匯款帳號"
                type="number"
                handler={accountChangeHandler}
                value={enteredAccount}
                feedback="請輸入有效的匯款帳號"
              />
            )}

            {probs.type === "USD" && (
              <GroupInputStr
                label="美金匯款 SWIFT code"
                type="text"
                handler={swiftCodeChangeHandler}
                value={enteredSwiftCode}
                feedback="美金申請請輸入有效的SwiftCode"
              />
            )}
            {probs.type === "USD" && (
              <GroupInputStr
                label="匯款帳號"
                type="number"
                handler={accountChangeHandler}
                value={enteredAccount}
                feedback="請輸入有效的匯款帳號"
              />
            )}
            {/* {probs.type === "TWD" && (
              <GroupInputStr
                label="匯款帳號戶名"
                type="text"
                handler={accountNameChangeHandler}
                value={enteredAccountName}
                feedback="請輸入有效的匯款帳號戶名"
              />
            )} */}
            {/* {probs.type === "USD" && (
              <GroupInputStr
                label="匯款帳號戶名"
                type="text"
                handler={accountNameChangeHandler}
                value={enteredAccountName}
                feedback="請輸入有效的匯款帳號戶名"
              />
            )} */}
            <Button type="submit">申請回饋</Button>
          </Form>
        </Container>
      )}
      {!showForm && !showSuccess && (
        <ConfirmInfo
          name={enteredName}
          email={enteredEmail}
          contactphone={enteredPhone}
          address={address}
          function={truncate}
          currency={probs.type}
          swiftcode={enteredSwiftCode}
          bankcode={enteredBankCode}
          // bankname={enteredBankName}
          account={enteredAccount}
          // accountname={enteredAccountName}
          lefthandler={showFormHandler}
          righthandler={submitSuccessHandler}
        />
      )}
    </>
  );
};

export default SubmitForm;
