import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

const GroupInputStr = (probs) =>{
    return(
        <Form.Group
            as={Row}
            className="mb-4"
            controlId="formHorizontalName"
        >
            <Form.Label>{probs.label}</Form.Label>
            <Col>
                <Form.Control
                    required
                    type={probs.type}
                    placeholder={probs.label}
                    onChange={probs.handler}
                    value={probs.value}
                />
                <Form.Control.Feedback type="invalid">
                    {probs.feedback}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
};

export default GroupInputStr;
