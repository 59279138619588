// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card_card__DH9F- {\n  background-color: #fff;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 10px;\n  margin: 10%;\n  padding: 5%;\n  justify-content: center;\n  align-items: center;\n  display: coluemn;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/component/UI/Card.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yCAAyC;EACzC,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".card {\n  background-color: #fff;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 10px;\n  margin: 10%;\n  padding: 5%;\n  justify-content: center;\n  align-items: center;\n  display: coluemn;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Card_card__DH9F-"
};
export default ___CSS_LOADER_EXPORT___;
