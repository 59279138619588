// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Choose_buttonList__AJPg3 {\n  margin: 10px;\n  display: flex;\n  justify-content: center;\n}\n\n@media screen and (max-width: 768px) {\n  .Choose_buttonList__AJPg3 {\n    flex-direction: column;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/component/pages/Choose.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".buttonList {\n  margin: 10px;\n  display: flex;\n  justify-content: center;\n}\n\n@media screen and (max-width: 768px) {\n  .buttonList {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonList": "Choose_buttonList__AJPg3"
};
export default ___CSS_LOADER_EXPORT___;
