// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_button__5XlHJ {\n  font: inherit;\n  background: linear-gradient(180deg, #9d63d2 0%, #7364ca 100%);\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,\n    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n  border-radius: 10px;\n  border: none;\n  color: white;\n  padding: 10px 30px;\n  cursor: pointer;\n  display: flex;\n  margin: auto;\n  transition: 0.5s;\n  transform: translateY(0);\n  flex-direction: row;\n  align-items: center;\n}\n\n.Button_button__5XlHJ:hover,\n.Button_button__5XlHJ:active {\n  transition: 0.5s;\n  transform: translateY(5px);\n  color: #ffffff;\n  border: none;\n}\n\n.Button_button__5XlHJ:focus {\n  outline: none;\n}\n", "",{"version":3,"sources":["webpack://./src/component/UI/Button.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6DAA6D;EAC7D;2CACyC;EACzC,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;;EAEE,gBAAgB;EAChB,0BAA0B;EAC1B,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".button {\n  font: inherit;\n  background: linear-gradient(180deg, #9d63d2 0%, #7364ca 100%);\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,\n    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n  border-radius: 10px;\n  border: none;\n  color: white;\n  padding: 10px 30px;\n  cursor: pointer;\n  display: flex;\n  margin: auto;\n  transition: 0.5s;\n  transform: translateY(0);\n  flex-direction: row;\n  align-items: center;\n}\n\n.button:hover,\n.button:active {\n  transition: 0.5s;\n  transform: translateY(5px);\n  color: #ffffff;\n  border: none;\n}\n\n.button:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Button_button__5XlHJ"
};
export default ___CSS_LOADER_EXPORT___;
