import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";

const GroupPhoneNumber = (probs) => {
  return (
    <Form.Group as={Row} mb="4" controlId="validationCustomUsername">
      <Form.Label>聯絡電話</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          type="number"
          aria-describedby="inputGroupPrepend"
          required
          onChange={probs.handler}
          value={probs.value}
        />
        <Form.Control.Feedback type="invalid">
          請輸入有效的手機號碼
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};
export default GroupPhoneNumber;
