import Form from "react-bootstrap/Form";


const GroupBankCode = (probs) =>{
    return(
        <div style={{ marginBottom: "30px" }}>
            <Form.Label column sm="6">
                銀行代碼：
            </Form.Label>
            <Form.Select
                required
                aria-label="Default select"
                onChange={probs.handler}
                value={probs.select}
            >
                <option value=""></option>
                {probs.bankcode.map(info => <option value={info.bank_code}> {info.bank_code}{info.name} </option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                請選擇銀行代碼
            </Form.Control.Feedback>
        </div>
    )
}
export default GroupBankCode;
