import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./Choose.module.css";
import SubButton from "../UI/SubButton";
import SubmitForm from "./SubmitForm";
import { useState } from "react";
// import useSuccess from "../../hooks/use-success";
import Success from "../UI/Success";

const Choose = (probs) => {
  const [chooseBlockChain, setChooseBlockChain] = useState(false);
  const [chooseCash, setChooseCash] = useState(false);
  const [chooseUSD, setChooseUSD] = useState(false);
  const [hideButton, setHideButton] = useState(false);

  const chooseBlockChainHandler = () => {
    setChooseBlockChain(true);
    setChooseCash(false);
    setChooseUSD(false);
  };

  const chooseCashHandler = () => {
    setChooseCash(true);
    setChooseBlockChain(false);
    setChooseUSD(false);
  };
  const chooseUSDHandler = () => {
    setChooseUSD(true);
    setChooseBlockChain(false);
    setChooseCash(false);
  };
  let success = {
    title: "申請成功！",
    subTitle: "已將您的申請資料送出，客服人員將會盡快為您處理",
    message:
      "預計手續時間需14個工作天，請耐心等候回饋入帳。可於14個工作天後（不含週末與國定假日）至您的帳戶進行確認",
  };

  const successHandler = () => {
    setHideButton(true);
  };
  return (
    <Container>
      <div className="title">
        <h1>申請回饋方式</h1>
      </div>
      <div className="subtitle">
        <p>
          6個NFT持有半年即可獲得750美元回饋
          <br />
          持有一年即可獲得1500美元回饋
          <br />
          持有兩年即可獲得2500美元回饋
          <br />
          總共獲得4750美元回饋
        </p>
      </div>
      {hideButton || (
        <div className={classes.buttonList}>
          <div className={classes.buttonList}>
            <SubButton onClick={chooseBlockChainHandler}>區塊鏈錢包</SubButton>
          </div>
          <div className={classes.buttonList}>
            <SubButton onClick={chooseCashHandler}>台幣匯款</SubButton>
          </div>
          <div className={classes.buttonList}>
            <SubButton onClick={chooseUSDHandler}>美金匯款</SubButton>
          </div>
        </div>
      )}

      {chooseBlockChain && !chooseCash && (
        <SubmitForm type="USDT" res={probs.res} onSuccess={successHandler} />
      )}
      {!chooseBlockChain && chooseCash && (
        <SubmitForm type="TWD" res={probs.res} onSuccess={successHandler} />
      )}
      {!chooseBlockChain && !chooseCash && chooseUSD && (
        <SubmitForm type="USD" res={probs.res} onSuccess={successHandler} />
      )}
      {hideButton && (
        <Success
          title={success.title}
          subTitle={success.subTitle}
          message={success.message}
        />
      )}
    </Container>
  );
};
export default Choose;
