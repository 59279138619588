import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "../UI/Button";
import { useState } from "react";
import classes from "./Home.module.css";
import Error from "../UI/Error";
import { useAccount } from "wagmi";
import useHttps from "../../hooks/use-https";
import Spinner from "react-bootstrap/Spinner";
import Success from "../UI/Success";
import Choose from "./Choose";

const Home = () => {
  const [error, setError] = useState({
    title: "請登入錢包並且驗證領取資格！",
    message: "（未驗證）",
  });
  const { address } = useAccount();

  const payload = {
    action: "address",
    address: {
      address: address, // owner address
    },
  };
  const { response, isLoading, err, sendRequest } = useHttps(payload);

  const truncate = (input, len) =>
    input?.length > len ? `${input?.substring(0, len)}...` : input;
  const findRefund = (res, time) => {
    let count = 0;
    if (time === 1) {
      res?.forEach((token) => {
        if (token?.RefundOneStatus) {
          count++;
        }
      });
    } else if (time === 2) {
      res?.forEach((token) => {
        if (token?.RefundTwoStatus) {
          count++;
        }
      });
    } else if (time === 3) {
      res?.forEach((token) => {
        if (token?.RefundThreeStatus) {
          count++;
        }
      });
    }
    return count;
  };

  let success = {
    title: `驗證通過！`,
    subTitle: `恭喜您的其中 ${response?.length} 個 NFT已符合領取資格！`,
    message: `可領取持有${findRefund(response, 1)}個半年、持有${findRefund(
      response,
      2
    )}個一年、持有${findRefund(response, 3)}個兩年的回饋。`,
  };

  const comfirmHandler = () => {
    sendRequest();
    if (address != null) {
      setError({
        title: "Oh！ 請檢查錢包地址與持有時間！",
        message: `您的錢包地址：${
          address ? truncate(address, 15) : "（未連接）"
        }尚未有任何符合領取資格的NFT`,
      });
    }
  };
  return (
    <Container>
      <div className="title">
        <h1>立即申請回饋</h1>
      </div>
      <div className="subtitle">
        <p>
          回饋申請完成後可於14個工作天後（不含週末與國定假日）至帳戶進行確認。
          <br />
          請將瀏覽器的區塊鏈錢包連結至持有欲領取回饋的NFT帳號
          <br />
          並點擊以下按鈕進行驗證
          <br />
        </p>
      </div>
      <div className={classes.Homebutton}>
        <Button onClick={comfirmHandler}>
          {!isLoading && `驗證領取資格`}
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </div>

      {address == null && <Error title={error.title} message={error.message} />}
      {address != null && response == null && (
        <Error title={error.title} message={error.message} />
      )}
      {address != null && response?.length > 0 && (
        <Success
          title={success.title}
          subTitle={success.subTitle}
          message={success.message}
        />
      )}
      {address != null && response?.length > 0 && <Choose res={response} />}
    </Container>
  );
};

export default Home;
