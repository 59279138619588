import { useState } from "react";

const useHttps = (payload) => {
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const sendRequest = async () => {
    setIsLoading(true);
    setError(null);
    setResponse(null);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const body = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: headers,
    };
    try {
      // const response = await fetch("http://35.194.151.97:3000/handle", body);
      const response = await fetch("https://nft.pgtalk.com/handle", body);

      if (!response.ok) {
        throw new Error("Request failed!");
      }
      const data = await response.json();
      setResponse(data.data.data);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  };

  return {
    response,
    isLoading,
    error,
    sendRequest,
  };
};
export default useHttps;
