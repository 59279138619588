import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";


const GroupWalletAddress = (probs) =>{
    return(
        <Form.Group
            as={Row}
            className="mb-4"
            controlId="formPlaintext"
            style={{ marginTop: "30px" }}
        >
            <Form.Label column sm="6">
                領取地址:
                <br />
                {probs.function(probs.address, 15)}
            </Form.Label>
        </Form.Group>
    )
}
export default GroupWalletAddress;
