import Card from "../UI/Card";
import classes from "./NFTelement.module.css";
import Container from "react-bootstrap/Container";
const NFTelement = (probs) => {
  return (
    <Container className={classes.cardGroup}>
      <Card className={classes.NFTcard}>
        <a href={probs.url} target="_blank" rel="noopener noreferrer">
          <img src={probs.filename} className={classes.NFTimage} alt="1" />
        </a>
        <h3 className={classes.subtitle}>{probs.subtitle}</h3>
      </Card>
    </Container>
  );
};

export default NFTelement;
